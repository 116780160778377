.branding {
  position: relative;
  margin: 20px 0 20px 20px;
  width: 185px;
  z-index: 101;
  @include medium-up {
    margin: 20px 0 20px 40px;
  }

  a {
    display: block;
  }

  .logo {
    display: block;
    width: 185px;
    height: 50px;
    // padding: 0 1px 1px 0;
    background-image: url('../images/logo-header.svg');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 184px 48px;
    // background-size: 100%;
  }
}