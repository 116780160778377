.subpage {
  main {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: 0 20px 40px 20px;
    background: $athensgray;
    @include medium-up {
      flex-wrap: wrap;
      padding: 0 50px 50px 50px;
    }
  }

  .main-content {
    width: 100%;
    @include medium-up {
      padding-top: 38px;
      width: 68%;
    }
  }
}

.subpage.fullwidth {
  main {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: 0 20px 40px 20px;
    background: $athensgray;
    @include medium-up {
      flex-wrap: wrap;
      padding: 0 50px 50px 50px;
    }
  }

  .main-content {
    margin-top: -40px;
    @include medium-up {
      margin-top: 0;
      padding-top: 0;
      width: 100%;
    }
  }
}
