footer.primary {
  background: $iron;

  .locations-grid {
    padding: 24px 20px;
    margin-top: 4px;
    @include xlarge-up {
      padding: 24px 40px;
      max-width: 1100px;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      @include fontsize(14 18);
      @include medium-up {
        display: flex;
        justify-content: space-between;
        margin: 0 -10px;
      }

      li {
        margin: 0 0 16px 0;
        @include medium-up {
          // width: 50%;
          padding: 0 10px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    p {
      margin: 0;
    }

    [itemprop='addressLocality'] {
      display: block;
      font-weight: 700;
      text-transform: uppercase;
      color: $cobalt;

      &.short-name {
        display: none;
      }

      a {
        color: $cobalt;

        &:hover,
        &:focus {
          color: $limedspruce;
        }
      }
    }

    a {
      color: $limedspruce;
      text-decoration: none;

      &:visited {
        color: $limedspruce;
      }

      &:hover,
      &:focus {
        color: $cobalt;
      }
    }
  }

  .inner {
    padding: 24px 10px;
    background: white;
    @include large-up {
      overflow: hidden;
      padding-left: 40px;
      padding-right: 40px;
    }

    p {
      margin: 0 10px;
      @include fontsize(14 18);
      @include large-up {
        margin: 0;
        padding-top: 10px;
        float: left;
        width: 50%;
      }
    }

    ul.logos {
      display: flex;
      align-items: center;
      margin: 10px 0 0 0;
      padding: 0;
      list-style: none;
      @include large-up {
        margin: 0;
        float: right;
        width: 50%;
        text-align: right;
        align-items: center;
        justify-content: flex-end;
      }

      li {
        display: inline-block;
        padding: 0 10px;
        min-width: 25%;
        max-width: 33%;
        @include large-up {
          min-width: 0;
          max-width: 75px;
        }
      }
    }
  }
}