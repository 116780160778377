.page-title {
  // flex: 0 0 calc(100% + 40px);
  flex: 0 0 auto;
  width: calc(100% + 40px);
  display: block;
  margin: 0 -20px 40px -20px;
  padding: 40px 20px 36px 20px;
  @include fontsize(33 28);
  font-family: $factoria;
  font-weight: 500;
  text-transform: uppercase;
  color: white;
  border-bottom: 5px solid white;
  background-color: $cobalt;
  background-image: url('../images/bg-banner-sp.svg');
  background-size: auto 100%;
  background-position: center left;
  background-repeat: no-repeat;
  @include medium-up {
    // flex: 0 0 calc(100% + 100px);
    flex: 0 0 auto;
    width: calc(100% + 100px);
    margin: 0 -50px;
    padding: 0px;
    @include fontsize(36 30);
    border-width: 4px;
    background-position: center right;
  }
  @include large-up {
    padding: 60px 50px 50px 50px;
  }
}

.main-content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 16px 0;
    font-family: $franklin;
  }

  h2 {
    @include fontsize(27 30);
    font-weight: 400;
    text-transform: uppercase;
    color: $cobalt;
    margin-top: 70px;

    &:first-of-type {
      margin-top: 0;
    }
  }

  h3 {
    @include fontsize(18 21);
    text-transform: uppercase;
    color: $limedspruce;
    font-weight: 700;
  }

  h4 {
    @include fontsize(18 21);
    color: $limedspruce;
    font-weight: 700;
  }

  h5 {
    @include fontsize(15 18);
    text-transform: uppercase;
    color: $limedspruce;
    font-weight: 700;
  }

  h6 {
    @include fontsize(15 18);
    color: $limedspruce;
    font-weight: 700;
  }

  p,
  ul,
  ol {
    margin: 0 0 16px 0;
    @include fontsize(18 30);
    font-weight: 400;
  }

  blockquote {
    margin: 0 0 16px 0;
    padding: 40px 40px 40px 35px;
    background: $iron;
    border-left: 5px solid $limedspruce;
    @include fontsize(24 36);
    @include medium-up {
      padding: 50px 50px 50px 45px;
    }

    p {
      @include fontsize(24 36);
      font-style: italic;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0 0 0 20px;
    list-style-type: none;

    li {
      margin: 0 0 22px 0;
      padding-left: 16px;
      border-left: 5px solid $limedspruce;
    }

    ul,
    ol {
      margin-bottom: 22px;
    }
  }

  ol {
    padding: 0 0 0 20px;
    list-style: none;
    counter-reset: ordered-list-counter;

    li {
      position: relative;
      margin: 0 0 22px 0;
      padding-left: 16px;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: counter(ordered-list-counter);
        counter-increment: ordered-list-counter;
        font-weight: 700;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    ol,
    ul {
      margin-bottom: 22px;
    }
  }
}