$base-font-size: 100%;
// assets path
$assets: '/Portals/_default/Skins/SC/assets/dist';
// colors
$limedspruce: #323e48;
$blackpearl: #030e1a;
$dodgerblue: #1884ec;
$cobalt: #003da7;
$supernova: #ffc800;
$bombay: #adb0b3;
$lightiron: #dddfe0;
$iron: #d6d8da;
$silver: #cccccc;
$athensgray: #eaebec;
$tallpoppy: #bf332c;
$black: #000;
// fonts
$franklin: 'Roboto', sans-serif;

@mixin franklin-con {
  font-family: 'Roboto Condensed', sans-serif;
}

@mixin franklin-com {
  font-family: 'Roboto Condensed', sans-serif;
}

$factoria: 'Rokkitt', serif;