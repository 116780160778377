* {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: auto;
}

*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  position: relative;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  font-size: 100%;
  background: white;
  color: $limedspruce;
  font-family: $franklin;
  margin: 0;
  overflow-x: hidden;
}

html.open {
  overflow: hidden;
}

.clearfix:after {
  display: table;
  clear: both;
  content: "";
}

a {
  color: $tallpoppy;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:visited {
    color: $tallpoppy;
  }

  &:hover,
  &:focus {
    text-decoration: underline;
    color: $cobalt;
  }
}

.btn-primary,
a.btn-primary {
  display: inline-block;
  cursor: pointer;
  margin: 0;
  background: $tallpoppy;
  color: white;
  @include fontsize(18 18);
  padding: 18px 44px;
  border: 0;
  font-weight: 700;
  text-transform: uppercase;
  height: auto;
  text-align: center;

  &:hover,
  &:focus {
    border: 0;
    color: white;
    background: $cobalt;
  }

  &.wide {
    width: 100%;
  }
}

input {
  appearance: none;
}

.element-invisible {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  width: 1px;
  height: 1px;
}

.clear {
  zoom: 1;

  &:before,
  &:after {
    display: table;
    content: "";
  }

  &:after {
    clear: both;
  }
}