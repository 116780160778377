.nav-open {
  cursor: pointer;
  position: absolute;
  top: 27px;
  right: 0;
  padding: 20px;
  font-size: 18px;
  color: $limedspruce;

  @include large-up {
    display: none;
  }

  &:hover,
  &:focus {
    color: $cobalt;
  }
}

.nav-close {
  cursor: pointer;
  position: absolute;
  top: 3px;
  right: 0;
  padding: 20px;
  font-size: 18px;
  color: white;

  @include large-up {
    display: none;
  }

  &:hover,
  &:focus {
    color: $supernova;
  }
}

.wrap-nav {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  padding-bottom: 60px;
  width: 100%;
  height: 100vh;
  border-top: 12px solid $supernova;
  background: $limedspruce;
  z-index: 1000;
  overflow-y: scroll;
  overflow-x: hidden;

  @include large-up {
    display: inline-block;
    position: absolute;
    top: 47px;
    right: 0;
    padding-bottom: 0;
    border-top: 0;
    height: auto;
    width: auto;
    float: right;
    text-align: right;
    z-index: 100;
    background: transparent;
    overflow: visible;
  }

  &.open {
    display: block;
  }
}

.nav-primary .ul1 {
  margin: 82px 0 0 0;
  padding: 0 20px;
  list-style: none;
  text-align: right;
  color: white;
  @include franklin-com;
  @include fontsize(22 24);
  font-weight: 700;
  text-transform: uppercase;

  @include large-up {
    display: inline-block;
    position: relative;
    // top: 50px;
    // right: 116px;
    margin: 0 112px 0 0;
    padding: 0;
    @include fontsize(13 12);
    color: $limedspruce;
  }

  @include xlarge-up {
    @include fontsize(16 18);
  }

  >li {
    margin: 0 0 15px 0;
    min-height: 32px;

    @include large-up {
      display: inline-block;
      position: relative;
      min-height: 0;
      margin: 0 24px 0 0;
      padding-bottom: 37px;

      &:hover>.ul2 {
        display: block;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    >span {
      position: relative;
      display: inline-block;
      padding: 5px 20px 0 20px;
      margin-right: 20px;
      height: 32px;
      font-size: 18px;
      border-right: 1px solid rgba(white, 0.25);
    }

    >a {
      display: inline-block;
      position: relative;
      bottom: -1px;
      color: $supernova;
      text-decoration: none;

      @include large-up {
        bottom: auto;
        color: $limedspruce;
        letter-spacing: 1px;

        &:hover,
        &:focus {
          color: $bombay;
        }
      }
    }
  }
}

.nav-primary .ul2 {
  display: none;
  margin: 0;
  padding: 1px 0;
  list-style: none;
  @include fontsize(20 24);
  font-weight: 400;
  text-transform: none;
  color: whiie;

  @include large-up {
    padding: 18px 0;
    position: absolute;
    top: 100%;
    left: -25px;
    border-top: 5px solid $supernova;
    @include fontsize(15 15);
    text-align: left;
    background: rgba($black, 0.75);

    &:before {
      position: absolute;
      top: -10px;
      left: 25px;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent $supernova transparent;
    }
  }

  @include xlarge-up {
    @include fontsize(18 18);
  }

  >li {
    display: block;

    @include large-up {
      white-space: nowrap;
    }

    >a {
      display: block;
      padding: 10px 0;
      color: white;
      text-decoration: none;

      &:visited {
        color: white;
      }

      &:hover,
      &:focus {
        color: $supernova;
      }

      @include large-up {
        padding: 6px 25px;
      }
    }
  }
}