header.primary {
  border-top: 12px solid $limedspruce;
  background: white;
}
// locations
header.primary {
  .locations-open {
    display: none;
    @include large-up {
      cursor: pointer;
      position: absolute;
      right: 30px;
      top: -11px;
      display: block;
      padding: 12px;
      width: 36px;
      text-align: center;
      color: $limedspruce;
      background: white;

      &:hover,
      &:focus {
        color: $bombay;
      }
    }
  }

  .wrap-locations {
    @include large-up {
      position: absolute;
      top: -11px;
      right: 66px;
      width: 0;
      height: 66px;
      background: white;
      overflow: hidden;
      @include transition(0.65s width ease-in-out);

      &.open {
        @include transition(0.65s width ease-in-out);
        width: 440px;
        @include xlarge-up {
          width: 480px;
        }

        .locations-open {
          cursor: default;
          color: $limedspruce;

          &:hover,
          &:focus {
            color: $limedspruce;
          }
        }
      }
    }

    ul {
      margin: 16px 0 0 0;
      padding: 0 20px;
      list-style: none;
      text-align: right;
      @include medium-up {
        display: flex;
        margin-top: 5px;
        margin-left: 10px;
        margin-right: 10px;
      }
      @include large-up {
        justify-content: space-between;
        // margin: 0;
        padding: 0;
        width: auto;
        text-align: left;
      }

      li {
        margin: 0 0 16px 0;
        width: 100%;
        @include medium-up {
          padding: 0 10px;
          width: 25%;
        }
        @include large-up {
          display: inline-block;
          margin: 0;
          padding: 0;
          width: auto;
          white-space: nowrap;

          &:last-child {
            padding-right: 0;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      p {
        margin: 0;
      }

      br {
        display: none;
      }

      span,
      abbr {
        display: none;
      }

      span.short-name,
      span[itemprop='telephone'] {
        display: block;
      }

      span.short-name {
        @include franklin-con;
        @include fontsize(14 24);
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.046875em;
        color: white;
        @include large-up {
          @include fontsize(12 15);
          color: $cobalt;
        }
        @include xlarge-up {
          @include fontsize(14 18);
        }
      }

      span[itemprop='telephone'] {
        @include franklin-con;
        @include fontsize(20 24);
        font-weight: 500;
        color: $limedspruce;
        @include large-up {
          @include fontsize(15 15);
          color: $limedspruce;
        }
        @include xlarge-up {
          @include fontsize(18 18);
        }

        a {
          text-decoration: none;
          color: $supernova;
          @include large-up {
            color: $limedspruce;
          }

          &:visited {
            color: $supernova;
            @include large-up {
              color: $limedspruce;
            }
          }

          &:hover,
          &:focus {
            color: white;
            @include large-up {
              color: $cobalt;
            }
          }
        }
      }
    }
  }
}
// search
header.primary {
  .search-open {
    display: none;
    @include large-up {
      cursor: pointer;
      position: absolute;
      top: -11px;
      right: 66px;
      display: block;
      padding: 12px;
      width: 36px;
      text-align: center;
      color: $limedspruce;
      background: white;

      &:hover,
      &:focus {
        color: $bombay;
      }
    }
  }

  .wrap-search {
    @include large-up {
      position: absolute;
      top: -13px;
      right: 102px;
      width: 0;
      height: 68px;
      background: white;
      overflow: hidden;
      @include transition(0.65s width ease-in-out);

      &.open {
        @include transition(0.65s width ease-in-out);
        width: 405px;
        @include xlarge-up {
          width: 450px;
        }

        .search-open {
          cursor: default;
          color: $limedspruce;

          &:hover,
          &:focus {
            color: $limedspruce;
          }
        }

        button {
          display: block;
        }
      }
    }
  }

  .search-form {
    margin: 20px 0 0 20px;
    width: calc(100% - 40px);
    border-top: 1px solid rgba(white, 0.25);
    border-bottom: 1px solid rgba(white, 0.25);
    overflow: hidden;
    @include large-up {
      position: relative;
      // position: absolute;
      // top: 0;
      // left: 36px;
      margin: 2px 0 0 10px;
      width: calc(100% - 10px);
      border: 1px solid $bombay;
      // background: white;
      // z-index: 90;
      // overflow: visible;
    }

    button {
      margin: 0;
      padding: 15px 15px 15px 0;
      width: 30px;
      float: left;
      border: 0;
      background: transparent;
      font-size: 20px;
      color: white;
      @include large-up {
        // display: none;
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px;
        width: 36px;
        height: 36px;
        font-size: 15px;
        text-align: center;
        color: $limedspruce;
        background: white;
      }

      &:hover,
      &:focus {
        color: $cobalt;
      }
    }

    input[type='search'] {
      padding: 0 0 0 5px;
      border: 0;
      width: calc(100% - 30px);
      height: 53px;
      float: right;
      background: transparent;
      @include franklin-con;
      @include fontsize(20 20);
      color: white;
      @include large-up {
        // display: none;
        width: 100%;
        height: 36px;
        float: none;
        @include fontsize(15 15);
        color: $limedspruce;
      }
      @include xlarge-up {
        @include fontsize(18 18);
      }
      @include placeholder {
        @include franklin-con;
        @include fontsize(20 20);
        color: $bombay;
        @include large-up {
          @include fontsize(15 15);
          color: $limedspruce;
        }
        @include xlarge-up {
          @include fontsize(18 18);
        }
      }

      &:focus {
        color: white;
        @include large-up {
          color: $limedspruce;
        }
        @include placeholder {
          color: white;
          @include large-up {
            color: $limedspruce;
          }
        }
      }
    }
  }
}