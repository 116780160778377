img {
  display: block;
  width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
  @include medium-up {
    max-width: 100%;
  }

  &[class*='left'] {
    margin: rem-calc(0 0 15px 0);
    @include medium-up {
      float: left;
      max-width: 40%;
      margin: rem-calc(12px 50px 20px 0);
    }
  }

  &[class*='right'] {
    margin: rem-calc(0 0 15px 0);
    @include medium-up {
      float: right;
      max-width: 40%;
      margin: rem-calc(12px 0 20px 50px);
    }
  }

  &[class*='center'] {
    margin: 0 auto rem-calc(15px) auto;
    @include medium-up {
      margin: 0 auto rem-calc(20px) auto;
    }
  }
}

figure {
  display: block;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
  @include medium-up {
    max-width: 100%;
  }

  img {
    display: block;
    margin: 0 auto;
  }

  img[class*='align'] {
    margin: 0 auto;
    float: none;
    @include medium-up {
      margin: 0 auto;
      float: none;
    }
  }

  &[class*='left'] {
    margin: rem-calc(0 0 15px 0);
    @include medium-up {
      float: left;
      max-width: 40%;
      margin: rem-calc(12px 50px 20px 0);
    }
  }

  &[class*='right'] {
    margin: rem-calc(0 0 15px 0);
    @include medium-up {
      float: right;
      max-width: 40%;
      margin: rem-calc(12px 0 20px 50px);
    }
  }

  &[class*='center'] {
    margin: 0 auto rem-calc(15px) auto;
    @include medium-up {
      margin: 0 auto rem-calc(20px) auto;
    }
  }

  figcaption {
    margin-top: 1px;
    font-style: italic;
    @include fontsize(15 18);
    color: white;
    background: $limedspruce;
    padding: 10px 25px;
  }
}